export default {
    animateQueue: _animateQueue
}

function _animateQueue(selector, animationArray, that) {
    that.animationCount = 0;
    _circleAnimation(selector, animationArray, that);
}

function _circleAnimation(selector, animationArray, that) {
    const node = document.querySelector(selector);
    $(node).addClass('animated ' + animationArray[that.animationCount]);
    node.style.display = "block";

    $(node).bind('animationend', function () {
        $(node).removeClass('animated ' + animationArray[that.animationCount]);
        $(node).unbind('animationend');

        that.animationCount++;
        if (that.animationCount < animationArray.length) {
            _circleAnimation(selector, animationArray, that);
        }
    });
}