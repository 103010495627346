import Constants from "../../../../utils/Constants"
import { Popover } from 'element-ui';
import Tools from "../../../../utils/Tools";

export default {
    data() {
        return {
            landPagePic: Constants.landPagePic,
            swiperOption0: {
                slideToClickedSlide: true,
                slidesPerView: 1,
                centeredSlides: true,
                spaceBetween: 15,
                grabCursor: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            },
            swiperOption1: {
                slideToClickedSlide: true,
                slidesPerView: 1.1,
                centeredSlides: true,
                spaceBetween: 15,
                grabCursor: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            },
            swiperOption2: {
                slideToClickedSlide: true,
                slidesPerView: 1.1,
                centeredSlides: true,
                spaceBetween: 15,
                grabCursor: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            },
            swiperOption3: null,
            //swiper data
            lans_descObjArray: [],
            super_functionObjArray: [],
            user_commentObjArray: []
        }
    },

    mounted() {
        this._refreshPage();
    },

    components: {
        "el-popover": Popover
    },

    computed: {
        mySwiper0() {
            return this.$refs.mySwiper0_PC.swiper
        },
        mySwiper1() {
            return this.$refs.mySwiper1_PC.swiper
        },
        mySwiper2() {
            return this.$refs.mySwiper2_PC.swiper
        },
        mySwiper3() {
            return this.$refs.mySwiper3_PC.swiper
        }
    },

    methods: {
        _refreshPage: function () {
            this._initSwiperData();
            this._initSwiper();
        },

        _initSwiper: function () {
            this.mySwiper0.slideTo(0, 1000, false);
            this.mySwiper1.slideTo(0, 1000, false);
            this.mySwiper2.slideTo(0, 1000, false);

            //init mySwiper3
            let distance = window.innerWidth * 0.65 / 2;
            let swiperOption3 = {
                slideToClickedSlide: true,
                watchSlidesProgress: true,
                slidesPerView: 'auto',
                centeredSlides: true,
                spaceBetween: 100,
                grabCursor: true,
                loop: true,
                loopedSlides: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next_3',
                    prevEl: '.swiper-button-prev_3',
                },
                on: {
                    progress: function (progress) {
                        for (let i = 0; i < this.slides.length; i++) {
                            let slide = this.slides.eq(i);
                            let slideProgress = this.slides[i].progress;
                            let modify = 1;
                            if (Math.abs(slideProgress) > 1) {
                                modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
                            }
                            let translate = slideProgress * modify * distance + 'px';
                            let scale = 1 - Math.abs(slideProgress) / 5;
                            let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                            slide.transform('translateX(' + translate + ') scale(' + scale + ')');
                            slide.css('zIndex', zIndex);
                            slide.css('opacity', 1);
                            if (Math.abs(slideProgress) > 3) {
                                slide.css('opacity', 0);
                            }
                        }
                    },
                    setTransition: function (transition) {
                        for (let i = 0; i < this.slides.length; i++) {
                            let slide = this.slides.eq(i);
                            slide.transition(transition);
                        }
                    }
                }
            };
            this.swiperOption3 = Object.assign({}, this.swiperOption3, swiperOption3);
            setTimeout(() => {
                this.mySwiper3.slideTo(2, 1000, false);
            }, 1000);
        },

        downLoadAppHandle: function () {
            if (Tools.isIPhone()) {
                window.location.href = "https://apps.apple.com/cn/app/%E9%B9%BF%E8%80%81%E5%B8%88%E8%AF%B4%E5%A4%96%E8%AF%AD-%E9%9B%B6%E5%9F%BA%E7%A1%80%E5%B0%8F%E8%AF%AD%E7%A7%8D%E5%85%A5%E9%97%A8%E5%AD%A6%E4%B9%A0/id1385950616";
            } else {
                window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=cn.lingodeer";
            }
        },

        goToOuterSiteHandle: function (index) {
            if (index === 1) {
                window.location.href = "https://apps.apple.com/cn/story/id1454206022";
            } else if (index === 2) {
                window.location.href = "https://mp.weixin.qq.com/s/fENNGMdxHJPU4tawUMCh8w";
            } else if (index === 3) {
                window.location.href = "https://www.wandoujia.com/award/blog/cn.lingodeer";
            }
        },

        _initSwiperData: function () {
            this.lans_descObjArray = [
                {
                    nm: "jp",
                    zh: "日语",
                    bm: "日本語",
                    des: "日本的通用语言，全球约1.28亿使用者。二次元、美食、旅行必备款。"
                },
                {
                    nm: "kr",
                    zh: "韩语",
                    bm: "한국어",
                    des: "朝鲜半岛的通用语言，全球约8000万使用者。韩剧、韩影、韩综必备。"
                },
                {
                    nm: "en",
                    zh: "英语",
                    bm: "English",
                    des: "世界通用语，全球使用人数3.6亿以上。全球化时代沟通无障碍首选。"
                },
                {
                    nm: "es",
                    zh: "西班牙语",
                    bm: "Español",
                    des: "联合国六大工作语言之一，全球约5.7亿使用者。明亮轻快，优美动听。"
                },
                {
                    nm: "de",
                    zh: "德语",
                    bm: "Deutsch",
                    des: "欧盟内使用最广泛的母语，全球约1.77亿使用者。理工、哲学、建筑必入。"
                },
                {
                    nm: "fr",
                    zh: "法语",
                    bm: "Français",
                    des: "“世界上最优美的语言”，全球约2.85亿使用者。时尚、美食、艺术之语。"
                },
                {
                    nm: "pt",
                    zh: "葡萄牙语",
                    bm: "Português",
                    des: "使用最广泛的语言之一，全球约2.8亿使用者。音乐、舞蹈、足球的国度。"
                }
            ];

            this.super_functionObjArray = [
                {
                    nm: "lx",
                    zh: "离线学习",
                    des: "即将搭乘飞机？手机流量有限？一键下载所有学习数据即可离线学习。"
                },
                {
                    nm: "jy",
                    zh: "静音学习模式",
                    des: "忘带耳机，不方便听音频？一键进入静音学习模式。"
                },
                {
                    nm: "jd",
                    zh: "阶段考核",
                    des: "检查课程掌握情况，阶段测试总结提升。"
                },
                {
                    nm: "cr",
                    zh: "抽认卡",
                    des: "5分钟也能充分利用，碎片时间高效复习，单词、句子随心记。"
                },
                {
                    nm: "xx",
                    zh: "学习提醒",
                    des: "设定固定时间提醒，养成自律好习惯。"
                },
                {
                    nm: "gx",
                    zh: "个性化设置",
                    des: "选出最适合你的学习环境，背景颜色、字体大小、音频速度可调节。"
                },
                {
                    nm: "ks",
                    zh: "跨设备同步",
                    des: "可在手机、平板电脑之间同步学习进度。"
                },
                {
                    nm: "ctb",
                    zh: "错题本",
                    des: "集中归纳错题，针对性强化练习薄弱环节。"
                }
            ];

            this.user_commentObjArray = [
                {
                    nm: "Jinji Kikko",
                    zh: "相信每一个知识点都是可以用到的",
                    des: "我学习的是日语，里面有三个阶段，每个阶段的难易程度不同，但知识点和语法点真的是很多。\n我一般都是一边用APP学，旁边备一个笔记本，因为里面的笔记真的特别有条理，完全的干货分享，相信每一个知识点都是可以用到的。除了考察发音、拼写、造句等，最后还有小视频可以自己进行配音～"
                },
                {
                    nm: "鹤沢",
                    zh: "非常感谢能有这么一款语言学习软件",
                    des: "真的超级棒！韩语本身拼音文字的形式就很难记，再加上发音相似的很多，但是这个APP循序渐进的学习方式真的很方便，一直重复记忆，慢慢地就都会认了，一步一步地从入门到进阶真的超有成就感。\n非常感谢能有这么一款语言学习软件，试过很多种就这种最好用了！"
                },
                {
                    nm: "皮卡丘的口袋",
                    zh: "像孩子一样学习才是最简单的",
                    des: "这是一款神奇的软件。市面上大量学英文的软件，做得好的学小语种的软件非常少。\n很难找到一款能让我有动力学小语种的软件。最近慢慢开始入门法语了，对我来说，像孩子一样学习才是最简单的，最有意思的方式。希望更多人能知道这款软件，感谢开发人员做了这么棒的一款软件！"
                },
                {
                    nm: "呆妹的小狼狗",
                    zh: "我用过的最好的外语学习app",
                    des: "之前用过很多外语学习软件，但都没坚持下来，大多都感觉太枯燥了。\n" +
                        "只有鹿老师是一股清流，课程设置非常合适，节奏很适合语言新手，每门语言都从发音开始，然后是入门进阶提升。\n" +
                        "学习的交互方式也很舒服，让你很想接着学下去！是我用过的最好的外语学习app！"
                },
                {
                    nm: "fengluyang",
                    zh: "安利给想学日语的大家",
                    des: "这款软件作为我日语入门遇到的第一款实在是太幸运了，真的很棒，从页面设计到课程设置，还有发音。\n" +
                        "认真学完五十音图后，才决定了要购买会员，也因此开启了学日语完全体。作为大一新兵蛋子，抽空每天学一课时还是很乐在其中的，安利给想学日语的大家！"
                },
                {
                    nm: "CHEINcc",
                    zh: "听说读写都包括了，语法讲解到位",
                    des: "看别人安利的就下载试试，真的很好用！有法语、日语、英语、韩语、西班牙语、德语、葡萄牙语。\n" +
                        "很适合自学党，或者对于上补习班需要课后巩固的人来说都很实用方便！听说读写都包括了，语法讲解也很到位。希望这个良心软件越来越好～加油加油加油！"
                }
            ];
        }
    }
}
